<template>
  <div>
    <h2 class="th-title">Laporan</h2>
    <a-card>
      <div class="d-flex justify-content-between mb-3">
        <a-input
          style="width: 350px"
          v-model:value="search"
          @change="handleSearch"
          placeholder="Cari berdasarkan username yang dilaporkan"
        >
          <template #prefix>
            <span>
              <search-outlined style="color: rgba(0, 0, 0, 0.25)" />
            </span>
          </template>
        </a-input>
        <a-select class="ml-2" style="width: 200px;" :value="reportType" @change="handleSelect" >
          <a-select-option value="all">
            All
          </a-select-option>
          <a-select-option value="content">
            Content
          </a-select-option>
          <a-select-option value="story">
            Story
          </a-select-option>
          <a-select-option value="livestream">
            Livestream
          </a-select-option>
          <!-- <a-select-option value="comment">
            Comment
          </a-select-option> -->
        </a-select>
      </div>
      <div>
        <a-table :class="$style.table" :columns="columns" :data-source="dataTable" :loading="loadingTable" :pagination="globalPagination" @change="onTableChange" bordered>
          <template #username="{ record }">
            <a-list>
              <a-list-item>
                  Pelapor: <router-link class="text-primary kit__utils__link" :to="{ name: 'Detail Akun', params: { id: record.reporter?._id } }">{{ record.reporter?.username }}</router-link>
                </a-list-item>
                <a-list-item>
                  Dilaporkan: <router-link class="text-primary kit__utils__link" :to="{ name: 'Detail Akun', params: { id: record.reported?._id } }">{{ record.reported?.username }}</router-link>
                </a-list-item>
            </a-list>
          </template>
          <template #postDetail="{ record }">
            <router-link
              v-if="record.reportType == 'content' || record.reportType == 'story' || record.reportType == 'livestream'"
              class="text-info kit__utils__link"
              :to="{
                name: 'Detail Post Akun',
                params: {
                  id: record.reported?._id,
                  post: record.postId,
                  typePost: record.reportType,
                },
              }"
              >See Post Detail - {{ record.createdAt }}</router-link
            >
          </template>
          <template #action="{ record }">
            <a-popover>
              <template #content>Berikan Peringatan</template>
              <a-popconfirm
                title="Kirim peringatan ke user?"
                ok-text="Yes"
                cancel-text="No"
                @confirm="showModalWarn(record)"
              >
                <button type="button" class="btn btn-outline-warning ml-1">
                  <i class="fe fe-alert-circle" aria-hidden="true"></i>
                </button>
              </a-popconfirm>
            </a-popover>
            <a-modal
              v-model:visible="modalWarnVisible"
              title="Beri peringatan kepada user"
              @ok="confirmWarn(warnUser)"
              @cancel="formWarn.warningMessage = ''"
            >
              <a-form layout="vertical" :model="formWarn">
                <a-form-item required label="Username" name="username">
                  <a-input
                    placeholder="Username user yang diberi peringatan"
                    v-model:value="warnUser.reported.username"
                    :disabled="true"
                  />
                </a-form-item>
                <a-form-item required label="Pesan" name="message" v-bind="validateInfosWarn.warningMessage" >
                  <a-input
                    placeholder="Masukan pesan peringatan..."
                    v-model:value="formWarn.warningMessage"
                  />
                </a-form-item>
              </a-form>
              <template #footer>
                <a-button
                  @click.prevent="confirmWarn(warnUser)"
                  key="submit"
                  type="primary"
                  html-type="submit"
                  >Beri Peringatan</a-button
                >
              </template>
            </a-modal>
            <a-popover>
              <template #content>Larang User</template>
              <a-popconfirm
                title="Berikan larangan ke user?"
                ok-text="Yes"
                cancel-text="No"
                @confirm="confirmBanUser(record.reported._id)"
              >
                <button type="button" class="btn btn-outline-danger ml-1" :disabled="record.reported.isBanned" >
                  <i class="fe fe-user-x" aria-hidden="true"></i>
                </button>
              </a-popconfirm>
            </a-popover>
          </template>
        </a-table>
      </div>
    </a-card>
  </div>
</template>

<script>
import { onMounted, reactive, ref, toRaw } from 'vue'
import { useStore } from 'vuex'
import { SearchOutlined, PlusOutlined } from '@ant-design/icons-vue'
import { Modal, notification } from 'ant-design-vue'
import {
  ExclamationCircleOutlined,
} from '@ant-design/icons-vue'
import { createVNode } from 'vue'
import { useForm } from '@ant-design-vue/use'
import apiClient from '@/services/axios'
import socket from '@/services/socket'
import { message } from 'ant-design-vue';

const columns = [
  {
    title: 'Id',
    dataIndex: 'key',
    key: 'key',
    slots: { customRender: 'key' },
    align: 'center',
    sorter: (a, b) => a.key - b.key,
  },
  {
    title: 'Username',
    align: 'center',
    width: 100,
    dataIndex: 'username',
    key: 'username',
    slots: { customRender: 'username' },
  },
  {
    title: 'Tipe Report',
    align: 'center',
    width: 200,
    dataIndex: 'reportType',
    key: 'reportType',
    slots: { customRender: 'reportType' },
  },
  {
    title: 'Detail Post',
    align: 'center',
    width: 200,
    dataIndex: 'postDetail',
    key: 'postDetail',
    slots: { customRender: 'postDetail' },
  },
  {
    title: 'Message',
    align: 'center',
    width: 200,
    dataIndex: 'message',
    key: 'message',
    slots: { customRender: 'message' },
  },
  {
    title: 'Aksi',
    align: 'center',
    width: 200,
    dataIndex: 'action',
    key: 'action',
    slots: { customRender: 'action' },
  },
]

export default {
  components: {
    SearchOutlined,
  },
  setup() {
    const store = useStore()
    let dataTable = ref([])
    let search = ref('')
    let reportType = ref('all')
    let globalPagination = ref({ current: 1, pageSize: 10 })
    let sortBy = ref('createdAt')
    let order = ref('desc')
    let loadingTable = ref(false)
    let modalWarnVisible = ref(false)
    let formWarn = reactive({
      warningMessage: '',
    })
    const rulesRef = reactive({
      warningMessage: [
        {
          required: true,
          message: 'Pesan peringatan harus diisi!',
          trigger: 'change',
        },
      ],
    })
    let warnUser = ref({
      reporter: null,
      reported: null,
    })

    onMounted(async () => {
      fetchReports()
    })

    const fetchReports = async (search) => {
      const API_getAllReport = await store.dispatch('report/FETCH_ALL_REPORT', { search, reportType: reportType.value, page: globalPagination.value.current, limit: globalPagination.value.pageSize, sortBy: sortBy.value, order: order.value });
      
      if (API_getAllReport) {
        const { data, totalAllData } = API_getAllReport;
        // console.log('data fetchTransaction =>', data)
        globalPagination.value = { ...globalPagination.value, total: totalAllData }
        dataTable.value = data
      }
    }

    const showModalWarn = (record) => {
      warnUser.value = record;
      modalWarnVisible.value = true
    }

    const { validate: validateWarn, validateInfos: validateInfosWarn } = useForm(formWarn, rulesRef)

    const confirmWarn = async (record) => {
      try {
        await validateWarn()
        Modal.confirm({
          title: 'Apa anda yakin ingin memberi peringatan?',
          icon: createVNode(ExclamationCircleOutlined),
          async onOk() {
            // console.log('record.userId', record.userId)
            // console.log('formWarn.warningMessage', formWarn.warningMessage)
            // ? jalanin socket message dr admin ke user
            const membersId = ['639feb70d52b7e3680c118a5', record.reported._id]

            socket.emit('SEND_MESSAGE', { userId: '639feb70d52b7e3680c118a5', membersId, txtMsg: formWarn.warningMessage }, (res) => {
              // console.log('res', res)
              if (res) {
                notification.success({
                  message: `Peringatan berhasil diberikan.`,
                })
              } else {
                notification.error({
                  message: `Peringatan gagal diberikan.`,
                })
              }
            })

            modalWarnVisible.value = false;
            formWarn.warningMessage = '';
          },
          onCancel() {
            formWarn.warningMessage = '';
          },
        })
      } catch (err) {
        console.log(err)
      }
    }

    const handleSearch = (e) => {
      // console.log('handleSearch', e.target.value);
      search.value = e.target.value;
      globalPagination.value = { ...globalPagination.value, current: 1 };
      fetchReports(search.value)
    }

    const handleSelect = (val) => {
      // console.log('handleSelect val', val)
      reportType.value = val;
      globalPagination.value = { ...globalPagination.value, current: 1 };
      fetchReports()
    }

    const onTableChange = async(pagination, filters, sorter) => {
      // console.log('pagination, filters, sorter', pagination, filters, sorter)
      globalPagination.value.current = pagination.current;
      if (sorter) {
        if (sorter.columnKey == 'key') sortBy.value = '_id';
        else sortBy.value = sorter.columnKey;
        order.value = sorter.order == 'ascend' ? 'asc' : 'desc';
      }
      fetchReports()
    }

    const confirmBanUser = async(record) => {
      try {
        const API_banningUser = await store.dispatch('report/BANNING_USER', { userId: record });
        
        if (API_banningUser) {
          message.success('Success ban user');
          fetchReports();
        }
      } catch (err) {
        message.error(`Error confirmBanUser: ${err}`);
      }
    }

    return {
      dataTable,
      columns,
      loadingTable,
      search,
      showModalWarn,
      modalWarnVisible,
      formWarn,
      rulesRef,
      confirmWarn,
      validateWarn,
      validateInfosWarn,
      handleSearch,
      reportType,
      handleSelect,
      globalPagination,
      sortBy,
      order,
      onTableChange,
      confirmBanUser,
      warnUser,
    }
  },
}
</script>
<style lang="scss" module>
@import './style.module.scss';
</style>
